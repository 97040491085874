/* You can add global styles to this file, and also import other style files */
.video-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    min-height: 100%;
    min-width: 100%;
    -webkit-transform-style: preserve-3d;
}
#background-video {
    width: 100%;
}
@media screen and (max-width: 650px) {
    #background-video {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        min-height: 100%;
        min-width: 100%;
        height: auto;
        width: auto;
        object-fit: cover;
        transform: translate(-50%,-50%);
    }
}